/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import './Timeline.css';
import { getDocumentType } from 'utils/documentDictionary';
import { Button } from 'components';
import { formatDateDisplay } from 'utils/dateFunctions';
import { oids } from 'configs';
import { useTranslation } from 'react-i18next';
import { colors, font_sizes } from '../../Style';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
    width: '100%',
    // backgroundColor: 'red',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeline: {
    backgroundColor: 'black',
    width: 1.5,
    alignSelf: 'center',
    marginTop: '1%',
    flexGrow: 1,
  },
  circle: {
    display: 'inline-block',
    backgroundColor: 'black',
    borderRadius: '50%',
    width: 10,
    height: 10,
  },
  reportDay: {
    fontSize: font_sizes.level1Title,
    fontWeight: 'bold',
    marginTop: 0,
    textAlign: 'right',
    marginBottom: 0,
    marginRight: '8%',
    color: colors.black,
  },
  reportTime: {
    fontSize: font_sizes.level4Title,
    marginTop: 0,
    textAlign: 'right',
    color: colors.black,
  },
  reportTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    marginTop: '1%',
  },
  reportSubjectName: {
    fontSize: font_sizes.level4Title,
    marginTop: 0,
    marginBottom: 0,
    color: colors.black,
  },
  reportSubjectId: {
    fontSize: font_sizes.level4Title,
    marginTop: 0,
    marginBottom: '15%',
    color: colors.black,
    whiteSpace: 'nowrap',
  },
}));

const StyledTimelineItem = withStyles({
  root: {
    width: '100%',
  },
})(TimelineItem);

const StyledTimelineOppositeContent = withStyles({
  root: {
    maxWidth: '70px',
  },
})(TimelineOppositeContent);

const StyledTimelineContent = withStyles({
  root: {
    width: '100%',
  },
})(TimelineContent);

function Month({ month, selectReport, examPdfCallback, isPdf = false }) {
  const { t } = useTranslation('PatientSummary');
  const classes = useStyles();
  const [modal, setModal] = useState({
    show: false,
    body: '',
    title: '',
  });
  const [alert, setAlert] = useState({
    show: false,
    body: '',
    title: '',
    onConfirm: null,
    onCancel: null,
  });

  const renderAlert = () => (
    <Dialog
      open={alert.show}
      onClose={() => setAlert({
        ...alert,
        visible: false,
      })}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{alert.title}</DialogTitle>
      <DialogContent>{alert.body}</DialogContent>
      <DialogActions>
        {/* {alert.footer} */}
        <Button
          variant="outline-danger"
          onClick={() => {
            if (alert.onCancel) alert.onCancel();
            setAlert({
              ...alert,
              show: false,
            });
          }}
        >
          {alert.cancelButtonTitle || 'Cancelar'}
        </Button>
        {alert.onConfirm ? (
          <Button variant="outline-succes" onClick={alert.onConfirm}>
            Enviar
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {month?.length > 0 && (
        <Timeline align="left">
          {month.map((report) => {
            if (!report) return null;
            return (
              <StyledTimelineItem>
                <StyledTimelineOppositeContent>
                  <Typography color="textSecondary">
                    {report.serviceStart_time_date && (
                      <p className={classes.reportDay}>
                        {report.serviceStart_time_date.split('/')[0]}
                        /
                        {report.serviceStart_time_date.split('/')[1]}
                      </p>
                    )}
                    {/* {report.serviceStart_time_time && (
                      <p className={classes.reportTime}>
                        {report.serviceStart_time_time}
                      </p>
                    )} */}
                  </Typography>
                </StyledTimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <Button
                    onClick={async () => {
                      try {
                        selectReport(
                          report.doc_id,
                          report.doc_ref,
                          report.documento_clinico,
                        );
                        if (isPdf) {
                          examPdfCallback(report);
                        }
                      }
                      catch (err) {
                        alert(err);
                      }
                    }}
                    key={report.doc_id}
                    style={{ width: '100%' }}
                  >
                    <Paper elevation={1} className={classes.paper}>
                      {/* {report.documento_clinico && <p className={classes.reportTitle}>{displayFullNameDoc(report.documento_clinico.split('.')[0])}</p>} */}
                      {report.documento_clinico && (
                        <p className={classes.reportTitle}>
                          {
                            getDocumentType({
                              docCode: report.documento_clinico.split('.')[0],
                            }).name
                          }
                        </p>
                      )}
                      {report.documento_clinico
                        === 'RegistroImunobiologico_FESF_v1.0'
                        && report.returnDate && (
                        <>
                          <p className="timeline-subject-name">
                            Data provável para a aplicação da próxima dose:
                          </p>
                          <p className="timeline-subject-name">
                            {formatDateDisplay(report.returnDate)}
                          </p>
                        </>
                      )}

                      {report.subject_name
                        && report.subject_name !== 'network_error'
                        && report.subject_name !== 'not_found' && (
                          <p className="timeline-subject-name">{isPdf ? `${t('TimelineDrawer-patientSummary-timeline-reportCard-solicitante')}: ${report.subject_name}` : `${t('TimelineDrawer-patientSummary-timeline-reportCard-professional')}: ${report.subject_name}`}</p>
                      )}
                      {report.subjectId_autor_oid === oids.cns && (
                        <p
                          className={classes.reportSubjectId}
                        >
                          {`${t('TimelineDrawer-patientSummary-timeline-chooseFilter-local')}: ${report.estabelecimento_saude}`}

                        </p>
                      )}
                      {report.subjectId_autor_oid !== oids.cns
                        && report.subjectId_cns && report.estabelecimento_saude !== null && (
                          <p
                            className={classes.reportSubjectId}
                          >
                            {`Local: ${report.estabelecimento_saude}`}

                          </p>
                      )}
                    </Paper>
                  </Button>
                </StyledTimelineContent>
              </StyledTimelineItem>
            );
          })}
          <Dialog
            open={modal.show}
            onClose={() => setModal({
              ...modal,
              visible: false,
            })}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">{modal.title}</DialogTitle>
            <DialogContent>{modal.body}</DialogContent>
            <DialogActions>
              {modal.footer}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setModal({
                  ...modal,
                  show: false,
                })}
              >
                Fechar
              </Button>
              {/* <Button
                  variant="outline-succes" onClick={() => {
                    logDebug('confirma');
                  }}
                >
                  Enviar
                </Button> */}
            </DialogActions>
          </Dialog>
        </Timeline>
      )}
      {renderAlert()}
    </>
  );
}

Month.propTypes = {
  month: PropTypes.node.isRequired,
  doc_id: PropTypes.string.isRequired,
  selectReport: PropTypes.func.isRequired,
};

export default Month;
