import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
} from '@material-ui/core';
import { formatDateDisplay } from 'utils/dateFunctions';
import { translate } from 'utils/AuxiliarFunctions';
import { useTranslation } from 'react-i18next';

function ServicesRequestsTabContent({ documents, selectReport }) {
  const { t } = useTranslation('PatientSummary');
  const headers = [t('myRegisters-patientSummary-healthSummary-tab-column-status'), t('myRegisters-patientSummary-healthSummary-tab-column-description'), t('myRegisters-patientSummary-healthSummary-tab-column-date')];
  const rows = documents.map((document) => {
    let documentCode = '';
    if(document.code.text) {
      documentCode = document.code.text;
    }
    else if(!document.code.text && document.code.coding[0]) {
      documentCode = document.code.coding[0];
    }
    const diagnosis = documentCode;

    return ({
      id: document.id,
      description: document.code.text ? document.code.text : diagnosis.display,
      status: translate(document.status),
      source: document.meta.tag ? document.meta.tag[0].code : document.meta.source,
      date: formatDateDisplay(document.authoredOn),
      tagExist: document.meta.tag ? true : false,
    });
  });

  return (
    <Table
      size="small"
      aria-label="enhanced table"
    >
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <TableCell
              key={i}
            >
              <h5>
                {header}
              </h5>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((document) => (
            <Tooltip
              key={document.id}
              placement="top"
              arrow
              title={t('tabsInfo-patientSummary-healthSummary-document-tooltip')}
            >
              <TableRow
                hover
                onClick={() => {
                  if(document.tagExist)
                  {
                    selectReport(document.source);
                  }
                }}
              >
                <TableCell padding="checkbox">{document.status}</TableCell>
                <TableCell>{document.description}</TableCell>
                <TableCell padding="checkbox">{document.date}</TableCell>
              </TableRow>
            </Tooltip>
          ))
        }
      </TableBody>
    </Table>
  );
}

ServicesRequestsTabContent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selectReport: PropTypes.func.isRequired,
};

export { ServicesRequestsTabContent };
