/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite';
import { Container, Row, Col } from 'react-bootstrap';
import MHDRequests from 'services/MHDRequests';
import { colors, prodMode } from 'configs';
import { PageError, Loading } from 'components';

import { IconContext } from 'react-icons/lib';
import striptags from 'striptags';
import { FaBan, FaFileMedical } from 'react-icons/fa';
import {
  flatten,
  verifyEmptyTag,
  isBase64,
} from 'utils/AuxiliarFunctions';
import { formatDateDisplay, getISODateLocalTime } from 'utils/dateFunctions';
import { getDocumentType } from 'utils/documentDictionary';
import { useVisualizerContext } from 'contexts/VisualizerContext';
import styles from './Style';
import PatientSummary from '../PatientSummary/PatientSummary';
import './Visualizer.css';
import DocumentError from './DocumentError';
import DocContentTitle from './DocContentTitle';
import ReportModal from './ReportModal/ReportModal';
import AppointmentTab from './AppointmentTab';
import WaitListTab from './WaitListTab';

import { Header } from './Header';
import { ArchetypeMenu } from './ArchetypeMenu';
import { Tab } from '../../components/Tab';
import { TabPanel } from '../../components/TabPanel';
import { TimelineViwer } from '../Visualizer/TimelineDrawer/TimelineViwer';
import { TimelineExamViwer } from '../Visualizer/TimelineDrawer/TimelineExamViwer';
import { useTranslation } from 'react-i18next';

function HandleDataFieldIsObject({ value, timeToShow, label }) {
  if (value.terminology_name === 'local') {
    return (
      <div style={{ width: '100%', marginRight: '5%' }}>
        <Row>
          <Col>
            {label !== '' && (
              <p className={css(styles.level4label)}>{`${striptags(label)}`}</p>
            )}
            {/* <br /> */}
          </Col>
          <Col xs={8}>
            {value.value !== '' && (
              <p className={css(styles.level4value)}>{timeToShow}</p>
            )}
          </Col>
          {/* <hr /> */}
        </Row>
        <hr />
      </div>
    );
  }

  return (
    <div style={{ width: '100%', marginRight: '5%', marginTop: 0 }}>
      <Row>
        <Col>
          <div id="Visualizer_detail_container">
            {label !== '' && (
              <p className={css(styles.level4label)}>{`${striptags(label)}`}</p>
            )}
          </div>
        </Col>
        <Col xs={8}>
          <p className={css(styles.level4value)}>
            {`${striptags(
              value.defining_code,
            )} - ${striptags(value.value)}`}

          </p>
        </Col>
      </Row>
      <hr />
    </div>
  );
}

function HandleDataFieldSimple({ timeToShow, label }) {
  return (
    <div style={{ width: '100%', marginRight: '5%' }}>
      <Row>
        <Col>
          {label !== '' && (
            <p className={css(styles.level4label)}>{striptags(label)}</p>
          )}
        </Col>
        <Col xs={8}>
          <p className={css(styles.level4value)}>{timeToShow}</p>
        </Col>
      </Row>

      <hr />
    </div>
  );
}

const codeString = {};

export default function Visualizer() {
  const { visualizerState, setVisualizerLoading } = useVisualizerContext();
  const [archetypeSelected, setArchetypeSelected] = useState(false);
  const [report, setReport] = useState();
  const [show, setShow] = useState();
  const [item, setItem] = useState();
  const { t } = useTranslation('PatientSummary');
  const [examViwer, setExamViwer] = useState();
  const [isPdf, setIsPdf] = useState(false);

  const reports = undefined;
  useEffect(() => {
    setExamViwer(<TimelineExamViwer
      patientId={visualizerState.patient.patientId}
      subjectId={visualizerState.subject.subjectId}
      organizationId={visualizerState.patient.organizationId}
      selectReport={selectReport}
      purposeOfUse={visualizerState.purposeOfUse}
      subjectToken={visualizerState.subject.subjectToken}
      subjectRole={visualizerState.subject.subjectRole}
      category="LAB"
      examPdfCallback={examPdfCallback}
    />);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (() => {
      //setVisualizerLoading(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createDataField(valueArg, labelArg) {
    // Função responsável por criar o nome de um campo e seu valor correspondente
    // Renderiza o campo mais interno do JSON, com estrutura {label: value}

    let value = valueArg;
    if(value === 'true') {
      value = 'sim';
    }else if (value === 'false') {
      value = 'não';
    }
    if (value === undefined) return <div />;

    const label = labelArg.split('__')[labelArg.split('__').length - 1];
    
    // Substituição dos códigos locais dos arquétipos
    if (value in codeString) value = codeString[value];
    if (label in codeString) {
      if (typeof codeString[label] === 'string') value = codeString[label];
      else if (value in codeString[label]) value = codeString[label][value];
    }
    const isObject = typeof value === 'object';
    const hasHour = label.includes('Hora') || label.includes('Data e hora');

    let timeToShow = '';
    if (isObject) {
      if (hasHour) {
        timeToShow = getISODateLocalTime(value?.value);
        timeToShow = new Date(timeToShow).toLocaleString('pt-BR');
      } else timeToShow = formatDateDisplay(striptags(value.value));
    } else if (value) {
      if (hasHour) {
        timeToShow = getISODateLocalTime(value);
        timeToShow = new Date(timeToShow).toLocaleString('pt-BR');
      } else timeToShow = formatDateDisplay(striptags(value));
    }
    if (isObject) {
      return (
        <HandleDataFieldIsObject
          value={value}
          timeToShow={timeToShow}
          label={label}
        />
      );
    }

    // Letras minúsculas no value para manter um padrão
    value = value.toLowerCase();
    value = value[0].toUpperCase() + value.slice(1);

    // if (value === 'True') value = 'Sim';
    // else if (value === 'False') value = 'Não';
    return <HandleDataFieldSimple timeToShow={timeToShow} label={label} />;
  }

  // async function setPatient(auxPatientId, auxPatientOID) {
  //   if (user?.userData?.mhd) {
  //     const userMHD = user.userData.mhd;
  //     try {
  //       if (auxPatientId === '' || auxPatientId === user.cpf) {
  //         setPatientName(user.name);
  //         setBirthdate(user.birthday);
  //         setGender(user.gender);
  //         setHeaderLoading(false);
  //         setPatientCNS(user.cns);
  //         if (userMHD?.name[1]) {
  //           setSocialName(
  //             userMHD.name.find((auxItem) => auxItem?.use === 'usual').text,
  //           );
  //         }
  //       } else {
  //         const patientJSON = await MHDRequests.getPatientJson(
  //           auxPatientId,
  //           auxPatientOID,
  //         );
  //         const patientIdentifiers = extractIdentifiers(patientJSON);
  //         setPatientName(patientJSON.name[0].text);
  //         setBirthdate(patientJSON.birthDate);
  //         setGender(patientJSON.gender);
  //         setHeaderLoading(false);
  //         setPatientCNS(patientIdentifiers.cns || 'Não Encontrado');
  //         if (patientJSON.name[1]) setSocialName(patientJSON.name[1].text);
  //       }
  //     } catch (err) {
  //       setPatientName('Não Encontrado');
  //       setBirthdate('NA');
  //       setGender('NA');
  //       setHeaderLoading(false);
  //     }
  //   } else {
  //     setPatientName('Não Encontrado');
  //     setBirthdate('NA');
  //     setGender('NA');
  //     setHeaderLoading(false);
  //   }
  // }

  function selectArchetype(archetype) {
    setItem(archetype);
    setArchetypeSelected(true);
  }

  function createReportMenu(objArg) {
    // Função que renderiza o menu com arquétipos para navegação
    // dentro de um RES
    // eslint-disable-next-line no-param-reassign
    let obj = objArg;
    if (objArg === undefined || objArg === null) {
      return (
        <div>
          Houve um erro ao tentar renderizar o documento, tente recarregar a
          página
        </div>
      );
    }
    else if (objArg === '') {
      return (null);
    }

    if (typeof objArg === 'string' && isBase64(objArg)) {
      return (
        <div className={css(styles.archetypeMenuContainer)}>
          <embed
            src={`data:application/pdf;base64,${objArg}`}
            type="application/pdf"
            width="100%"
            height="450rem"
          />
        </div>
      );
    }

    if (Object.keys(obj).length === 1) obj = obj[Object.keys(obj)[0]];

    const keys = Object.keys(obj);

    const key_group = [];
    for (let i = 0; i < keys.length; i += 4) key_group.push(keys.slice(i, i + 4));

    while (key_group[key_group.length - 1].length !== 4) key_group[key_group.length - 1].push('');

    let auxItem = item;

    if (!archetypeSelected) {
      setArchetypeSelected(true);
      auxItem = keys[0];
      setItem(keys[0]);
    }

    const tempjson = {};
    if (typeof obj[auxItem] === 'string') tempjson[auxItem] = obj[auxItem];
    else tempjson[auxItem] = verifyEmptyTag(obj[auxItem]);
    const key = auxItem;

    const condDataField = (typeof tempjson[key] === 'string'
        && Object.keys(tempjson).length === 1)
      || 'terminology_name' in tempjson;

    const title = obj[auxItem];
    return (
      <div>
        <div>
          <div className={css(styles.archetypeMenuContainer)}>
            <Row>
              <Col xs={4} sm={4} md={4} lg={3} xl={3}>
                {ArchetypeMenu(key_group, selectArchetype, auxItem)}
              </Col>
              <Col xs={8} sm={8} md={8} lg={9} xl={9} style={{ paddingLeft: '60px' }}>
                {condDataField
                  ? createDataField(tempjson[key], auxItem)
                  : createReportContent(title)}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  function createReportContent(obj) {
    if (!obj) return null;
    // Função responsável por renderizar o título de uma seção
    // dentro do arquétipo
    let reportContentCreated = false;
    let create_Col = true;
    let first_Col = [];
    const condOneItem = typeof obj[Object.keys(obj)[0]] === 'string'
      && Object.keys(obj).length === 1;
    let oneItemJson = {};
    if (condOneItem) {
      oneItemJson = obj;
      const key = Object.keys(oneItemJson)[0];
      return <Row>{createDataField(oneItemJson[key], key)}</Row>;
    }

    return (
      <div>
        {Object.keys(obj).map((auxItem, index) => {
          const tempjson = {};
          let verifyEmpty = [];
          let key = '';
          if (typeof obj[auxItem] === 'string') {
            tempjson[auxItem] = obj[auxItem];
            key = auxItem;
          } else {
            verifyEmpty = verifyEmptyTag(obj[auxItem]);
            if (Array.isArray(verifyEmpty)) {
              tempjson[auxItem] = verifyEmpty[0];
              key = verifyEmpty[1];
            } else {
              tempjson[auxItem] = obj[auxItem];
              key = auxItem;
            }
          }
          if (reportContentCreated) create_Col = true;

          // Verifica se o objeto em questão já é um
          // data field -> estrutura {label: value}
          // Nesse caso, ao inves de renderizar o
          // título, chama diretamente
          // a função que renderiza os campos de
          // informação (em duas colunas)
          const condDataField = (typeof tempjson[key] === 'string'
              && Object.keys(tempjson).length === 1)
            || 'terminology_name' in tempjson;
          if (condDataField) {
            reportContentCreated = false;
            create_Col = !create_Col;
            if (create_Col) return <Row>{createDataField(tempjson[key], key)}</Row>;

            first_Col = [tempjson[key], key];

            return <div />;
          }

          first_Col = [tempjson[auxItem], key];

          reportContentCreated = true;
          return (
            <div key={index}>
              {!create_Col && createDataField(first_Col[0], first_Col[1])}
              {Object.keys(tempjson).map((auxItem2, index2) => (
                <div key={index2}>
                  {/* Renderiza o subtítulo */}
                  {DocContentTitle(auxItem2)}

                  {/* Renderiza o array com os dados */}
                  {docContentData(tempjson[auxItem2], key)}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  function docContentData(obj, key) {
    // Função que renderiza o array contendo um conjunto de "data_fields",
    // que é o nível de informação mais interno da renderização
    if (key) {
      const temp = obj;
      obj = {};
      obj[key] = temp;
    }
    const temp = flatten(obj);
    // eslint-disable-next-line no-param-reassign
    obj = Object.keys(temp);
    // Cria um array contendo os values em pares, a fim de renderizar duas colunas
    const new_temp = [];
    for (let i = 0; i < obj.length; i += 2) new_temp.push([obj[i], obj[i + 1]]);

    return (
      <div>
        {new_temp.map((auxItem) => {
          // Verifica se o objeto em questão só possui
          // value (label já renderizado em create_level_X)
          for (let i = 0; i < auxItem.length; i += 1) {
            let obj_is_empty = true;
            for (const auxKey in temp[auxItem[i]]) if (auxKey) obj_is_empty = false;

            // Se o título do campo já foi renderizado, chama o createDataField com label vazio
            // Ou seja, renderiza somente o value
            if (obj_is_empty && auxItem[i] !== undefined) temp[auxItem[i]] = 'Não informado';
          }
          // Caso contrário, renderiza duas colunas contendo {label: value}
          return (
            <Row key={auxItem}>
              {auxItem[0] !== undefined
                && createDataField(temp[auxItem[0]], auxItem[0])}
              {auxItem[1] !== undefined
                ? createDataField(temp[auxItem[1]], auxItem[1])
                : null}
            </Row>
          );
        })}
      </div>
    );
  }

  async function selectReport(idArg, documento_clinico) {
    let binaryId = idArg;
    if (binaryId) {
      binaryId = binaryId.split('oid:');
      binaryId = binaryId[1] || binaryId[0];
    }
    // eslint-disable-next-line no-param-reassign
    if (binaryId) {
      setArchetypeSelected(false);

      let returned_report = null;
      returned_report = await MHDRequests.getReport({
        binaryId,
        organizationId: visualizerState.patient.organizationId,
        subjectToken: visualizerState.subject.subjectToken,
        subjectId: visualizerState.subject.subjectId,
        subjectRole: visualizerState.subject.subjectRole,
        purposeOfUse: visualizerState.purposeOfUse,
      });

      if (returned_report === 'network_error' || !returned_report) returned_report = {};
      else if (documento_clinico) {
        if (documento_clinico.indexOf('http://') === 0 || documento_clinico.indexOf('https://') === 0) {
          // url absoluta
          window.open(documento_clinico);
          return;
        }
          let docKey = Object.keys(returned_report);
        if (Array.isArray(docKey) && docKey.length) {
          docKey = docKey[0];
          // const docName = displayFullNameDoc(documento_clinico.split('.')[0]);
          const docName = getDocumentType({
            docCode: documento_clinico.split('.')[0],
          }).name;

          if (docKey === 'pdf') {
            setIsPdf(true);
            Object.defineProperty(
              returned_report,
              docName,
              Object.getOwnPropertyDescriptor(returned_report, docKey),
            );
            delete returned_report[docKey];
          }
          else {
            setIsPdf(false);
          }
        }
      }
      setReport(returned_report);
      setShow(true);
    } else if (documento_clinico) {

      if (documento_clinico.indexOf('http://') === 0 || documento_clinico.indexOf('https://') === 0) {
        // url absoluta
        window.open(documento_clinico);
      }
    }
  }

  let reportTitle = '';
  if (report) {
    reportTitle = Object.keys(report);
    reportTitle = reportTitle[0];
  }

  if (visualizerState.loading) {
    return (
      <div className="loadingDiv">
        <Loading size="large" type="circular" variation="indeterminate" />
      </div>
    );
  }

  if (typeof reports === 'string') {
    let message;
    let icon;
    let tryAgain = false;
    if (visualizerState.patient.subjectId === visualizerState.patient.patientId) {
      tryAgain = true;
      icon = (
        <IconContext.Provider
          value={{ color: colors.accentColor, size: '30%' }}
        >
          <FaFileMedical style={{ alignSelf: 'center' }} />
        </IconContext.Provider>
      );
      message = 'Erro na busca de registros, favor tentar novamente.';
    } else {
      icon = (
        <IconContext.Provider
          value={{ color: colors.accentColor, size: '30%' }}
        >
          <FaBan style={{ alignSelf: 'center' }} />
        </IconContext.Provider>
      );
      message = 'Política atual do Paciente não permite a visualização de documentos';
    }
    return PageError(message, icon, tryAgain);
  }

  const setExamCategory = (category, btnID) => {
    document.getElementById("btn"+category).classList.add("ExamButton-enabled");
    document.getElementById(btnID).classList.remove("ExamButton-enabled");

    document.getElementById("btn"+category).classList.remove("ExamButton-disabled");
    document.getElementById(btnID).classList.add("ExamButton-disabled");
    setExamViwer(null);

    setTimeout(() => setExamViwer(<TimelineExamViwer
      patientId={visualizerState.patient.patientId}
      subjectId={visualizerState.subject.subjectId}
      organizationId={visualizerState.patient.organizationId}
      selectReport={selectReport}
      purposeOfUse={visualizerState.purposeOfUse}
      subjectToken={visualizerState.subject.subjectToken}
      subjectRole={visualizerState.subject.subjectRole}
      category={category}
      examPdfCallback={examPdfCallback}
    />), 500);
  };

  async function examPdfCallback(report) {
    // MHDRequests.getReport - tem tratamento para pdf
    const pdfResponse = await MHDRequests.getReport({
      binaryId: report.doc_id,
      organizationId: visualizerState.patient.organizationId,
      subjectToken: visualizerState.subject.subjectToken,
      subjectId: visualizerState.subject.subjectId,
      subjectRole: visualizerState.subject.subjectRole,
      purposeOfUse: 'Atendimento',
    });
    if (!pdfResponse) {
      return;
    }
    const contentType = 'application/pdf';
    const byteCharacters = atob(pdfResponse.pdf);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: contentType});

    const blob = b64toBlob(pdfResponse.pdf, contentType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  return (
    <div className={css(styles.appContainer)} style={{ marginTop: '0%' }}>
      {isPdf ? '' : <ReportModal
        show={show}
        setShow={(value) => setShow(value)}
        title={reportTitle}
        contextText={null}
        body={(
          <>
            {typeof report === 'string' && <DocumentError />}
            {report && createReportMenu(report[Object.keys(report)[0]])}
          </>
        )}
      />}

      <div className="visualizer-container">
        {visualizerState.loading ? (
          <div className="Visualizer_header center">
            <Loading size="small" type="circular" />
          </div>
        ) : (
          <Header
            patientName={visualizerState.patient.name}
            birthdate={visualizerState.patient.birthdate}
            gender={visualizerState.patient.gender}
            patientId={visualizerState.patient.patientId}
            socialName={visualizerState.patient.socialName}
            patientOID={visualizerState.patient.patientOID}
            patientCNS={visualizerState.patient.patientCNS}
            organizationId={visualizerState.patient.organizationId}
            subjectId={visualizerState.subject.subjectId}
            subjectToken={visualizerState.subject.subjectToken}
            subjectRole={visualizerState.subject.subjectRole}
            purposeOfUse={visualizerState.purposeOfUse}
            selectReport={selectReport}
          />
        )}

        <div className="VisualizerPage">
          <Container fluid>
            <Row>
              <Col>
                <Tab>
                  <TabPanel title={t('PatientSummary-healthSummary-patient')}>
                    <div className="Visualizer_documentViewer_patientSumm">
                      <PatientSummary
                        patientId={visualizerState.patient.patientId}
                        selectReport={selectReport}
                        purposeOfUse={visualizerState.purposeOfUse}
                        subjectId={visualizerState.subject.subjectId}
                        subjectToken={visualizerState.subject.subjectToken}
                        subjectRole={visualizerState.subject.subjectRole}
                        organizationId={visualizerState.patient.organizationId}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel title={t('TimelineDrawer-patientSummary-assistance-contracts')}>
                    <div>
                      <TimelineViwer
                        patientId={visualizerState.patient.patientId}
                        subjectId={visualizerState.subject.subjectId}
                        organizationId={visualizerState.patient.organizationId}
                        selectReport={selectReport}
                        purposeOfUse={visualizerState.purposeOfUse}
                        subjectToken={visualizerState.subject.subjectToken}
                        subjectRole={visualizerState.subject.subjectRole}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel title={t('tabsInfo-patientSummary-healthSummary-tab-exam-results')}>
                    <div style={{ float: 'left' }} className="normal-exam-results">
                      {examViwer}
                    </div>
                  </TabPanel>
                  <TabPanel title={"Agendamentos"}>
                    <AppointmentTab />
                  </TabPanel>
                  <TabPanel title={"Lista de Espera"}>
                    <WaitListTab />
                  </TabPanel>
                </Tab>
                {/* <div className="Visualizer_documentViewer_patientSumm">
                  <PatientSummary
                    patientId={visualizerState.patient.patientId}
                    selectReport={selectReport}
                    purposeOfUse={visualizerState.purposeOfUse}
                    subjectId={visualizerState.subject.subjectId}
                    subjectToken={visualizerState.subject.subjectToken}
                    subjectRole={visualizerState.subject.subjectRole}
                    organizationId={visualizerState.patient.organizationId}
                  />
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
