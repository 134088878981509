import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { colors } from 'configs';
import { useTranslation } from 'react-i18next';
import { font_sizes } from '../../Style';
import Month from './Month';

const styles = StyleSheet.create({
  monthTitle: {
    marginLeft: '50px',
    marginTop: '2%',
    marginRight: '2%',
    marginBotton: '2%',
    fontSize: font_sizes.level2Title,
    fontWeight: 'bold',
    color: colors.accentColor,
  },
});

const Year = ({ year, selectReport, examPdfCallback, isPdf = false }) => {
  const { t } = useTranslation('Months');

  return (
    <div>
      {year.map((month, index) => (
        <div key={index}>
          {month.data.length !== 0
        && <p className={css(styles.monthTitle)} style={{ display: 'none' }}>{t(month.title)}</p>}
          <Month
            month={month.data}
            selectReport={selectReport}
            examPdfCallback={examPdfCallback}
            isPdf={isPdf}
          />
        </div>
      ))}
    </div>
  );
};

export default Year;
