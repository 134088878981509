/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */

import {
  retrieveData, fetchWithTimeout, verifyUnauthorized, verifyCPF, logDebug,
} from 'utils/AuxiliarFunctions';

import { oids } from 'utils/Constants';
import { endpoints } from 'configs';
import xdsToken from './xdsToken';

const {
  ADMFHIR,
} = endpoints;

export default class AdminFhirRequests {
  static catchError = (err, from) => {
    logDebug(`ERROR in AdminFhirRequests: ${from}: `, err);
    switch (parseInt(err.message, 10)) {
      case (500):
        return 'internal_error';
      case (504):
        return 'timeout';
      case (400):
        return 'bad_request';
      case (401):
        return 'unauthorized';
      case (403):
        return 'forbidden';
      case (404):
      case (410):
        return 'not_found';
      case (409):
        return 'conflict';
      default:
        return 'network_error';
    }
  }

  static async getPractitioner(id, oid) {
    const mpiId = `${oid}%7C${id}`;

    const url = `${ADMFHIR}/Practitioner?identifier=urn:oid:${mpiId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const request = {
      method: 'GET',
      headers: myHeaders,
    };

    // logDebug('requestPRINTADO :>> ', request);
    const PatientResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'GetPatient'));

    if (typeof PatientResponse === 'string') return PatientResponse;

    const responseJson = await PatientResponse.json();
    return responseJson;
  }

  static async getPractitionerRoles(cnes, id, oid) {
    let mpiId = `${oid}-${id}`;
    if (cnes) mpiId = `${cnes}-${mpiId}`;
    const url = `${ADMFHIR}/PractitionerRole/${mpiId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const request = {
      method: 'GET',
      headers: myHeaders,
    };

    const PractitionerRolesResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'getPractitionerRoles'));

    if (typeof PractitionerRolesResponse === 'string') return PractitionerRolesResponse;

    const responseJson = await PractitionerRolesResponse.json();
    return responseJson;
  }

  static async addPatientIdentifier(id, oid, data, index) {
    const mpiId = `${oid}-${id}`;
    const url = `${ADMFHIR}/Practitioner/${mpiId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/json-patch+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = [
      {
        op: 'add',
        path: `/identifier/${index}`,
        value: data,
      },
    ];

    const request = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(body),
    };

    logDebug(body);
    const PatientResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'putPatient'));

    if (typeof PatientResponse === 'string') return PatientResponse;

    const responseJson = await PatientResponse.json();
    return responseJson;
  }

  static async removePatientIdentifier(id, oid, index) {
    const mpiId = `${oid}-${id}`;
    const url = `${ADMFHIR}/Practitioner/${mpiId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/json-patch+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const body = [
      {
        op: 'remove',
        path: `/identifier/${index}`,
      },
    ];

    const request = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(body),
    };

    logDebug(body);
    const PatientResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'putPatient'));

    if (typeof PatientResponse === 'string') return PatientResponse;

    const responseJson = await PatientResponse.json();
    return responseJson;
  }

  static async putPractitioner(id, data) {
    const url = `${ADMFHIR}/Practitioner/${id}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const request = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(data),
    };

    // logDebug('requestPRINTADO :>> ', request);
    const PractitionerResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'putPractitioner'));

    if (typeof PractitionerResponse === 'string') return PractitionerResponse;

    const responseJson = await PractitionerResponse.json();
    return responseJson;
  }

  static async putPractitionerRoles(cnes, id, oid, data) {
    const mpiId = `${cnes}-${oid}-${id}`;
    const url = `${ADMFHIR}/PractitionerRole/${mpiId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const request = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(data),
    };

    // logDebug('requestPRINTADO :>> ', request);
    const PractitionerRolesResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'putPractitionerRoles'));

    if (typeof PractitionerRolesResponse === 'string') return PractitionerRolesResponse;

    const responseJson = await PractitionerRolesResponse.json();
    return responseJson;
  }

  static async getAdminPractitionerRolesByUf(
    {
      uf,
      filter = {
        ROLE_GestorEstabelecimento: true,
        ROLE_GestorEstadual: true,
        ROLE_GestorMunicipal: true,
      },
    },
  ) {
    const rolesFilter = Object.keys(filter).filter((item) => (filter[item] === true ? item : '')).join(',');
    // const roles = 'ROLE_GestorEstabelecimento,ROLE_GestorEstadual,RL_GestorEstabelecimento,RL_GestorEstadual';
    const url = `${ADMFHIR}/PractitionerRole?role=${rolesFilter}&organization:Organization.address-state=${uf}&_include=PractitionerRole:practitioner&_include=PractitionerRole:organization`;
    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response.json());
      })
      .catch((err) => AdminFhirRequests.catchError(err, 'getPractitionerRoleByUf'));

    verifyUnauthorized(FHIR_response);
    return FHIR_response;
  }

  static async getAdminPractitionerRolesByCity(
    {
      city,
      filter = {
        ROLE_GestorEstabelecimento: true,
        ROLE_GestorEstadual: true,
        ROLE_GestorMunicipal: true,
      },
    },
  ) {
    const rolesFilter = Object.keys(filter).filter((item) => (filter[item] === true ? item : '')).join(',');
    const url = `${ADMFHIR}/PractitionerRole?role=${rolesFilter}&organization:Organization.address-city=${city}&_include=PractitionerRole:practitioner&_include=PractitionerRole:organization`;
    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response.json());
      })
      .catch((err) => AdminFhirRequests.catchError(err, 'getPractitionerRoleByUf'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async getAdminPractitionerRolesByOrganization(
    {
      organization,
      filter = {
        ROLE_GestorEstabelecimento: true,
        ROLE_GestorEstadual: true,
        ROLE_GestorMunicipal: true,
      },
    },
  ) {
    const rolesFilter = Object.keys(filter).filter((item) => (filter[item] === true ? item : '')).join(',');
    const url = `${ADMFHIR}/PractitionerRole?role=${rolesFilter}&organization=${oids.organization}-${organization}&_include=PractitionerRole:practitioner&_include=PractitionerRole:organization`;
    await xdsToken.getBearerToken();
    const applicationToken = await retrieveData('application_token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const timeout = 15000;
    // eslint-disable-next-line prefer-const
    const FHIR_response = await fetchWithTimeout(url, requestOptions, timeout)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return (response.json());
      })
      .catch((err) => AdminFhirRequests.catchError(err, 'getPractitionerRoleByUf'));

    verifyUnauthorized(FHIR_response);

    return FHIR_response;
  }

  static async deleteRole(id) {
    const url = `${ADMFHIR}/PractitionerRole/${id}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const request = {
      method: 'DELETE',
      headers: myHeaders,
    };

    // logDebug('requestPRINTADO :>> ', request);
    const PractitionerRolesResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'putPractitionerRoles'));

    if (typeof PractitionerRolesResponse === 'string') return PractitionerRolesResponse;

    const responseJson = await PractitionerRolesResponse.json();
    return responseJson;
  }

  static async getOrganization(organization) {
    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');
    
    const url = `${ADMFHIR}/Organization?identifier=${organization}`;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    const organizationsSearchResult = await fetchWithTimeout(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) throw new Error(response.status);
        return response.json();
      })
      .catch((err) => AdminFhirRequests.catchError(err, 'getOrganization'));

    const organizationJSON = organizationsSearchResult.entry[0]?.resource;

    verifyUnauthorized(organizationJSON);

    if (typeof organizationJSON === 'string') return organizationJSON;
    return organizationJSON;
  }

  static async addPatientToGroup({ patientId, groupId }) {
    const url = `${ADMFHIR}/Group/${groupId}`;

    await xdsToken.getBearerToken();
    const token = await retrieveData('token');
    const applicationToken = await retrieveData('application_token');

    if (!token) return 'missing_token';

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('Content-Type', 'application/xml-patch+xml');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const oid = verifyCPF(patientId) ? oids.cpf : oids.cns;
    const body = `<diff xmlns:fhir="http://hl7.org/fhir">
      <add sel="/fhir:Group">
        <fhir:member>
          <fhir:entity>
            <fhir:reference value="Patient/${oid}-${patientId}"/>
          </fhir:entity>
        </fhir:member>
      </add>
    </diff>`;

    const request = {
      method: 'PATCH',
      headers: myHeaders,
      body,
    };

    // logDebug('requestPRINTADO :>> ', request);
    const PractitionerRolesResponse = await fetchWithTimeout(url, request).then((response) => {
      if (response.status < 200 || response.status > 299) throw new Error(response.status);
      return (response);
    })
      .catch((err) => AdminFhirRequests.catchError(err, 'putPractitionerRoles'));

    if (typeof PractitionerRolesResponse === 'string') return PractitionerRolesResponse;

    const responseJson = {
      response: await PractitionerRolesResponse.text(),
    };
    return responseJson;
  }

  static async getOrganizationName(organization) {
    try {
      const organizationData = await AdminFhirRequests.getOrganization(organization);

      if (typeof organizationData === typeof '') return organization;
      return organizationData.alias[0];
    } catch (err) {
      return organization;
    }
  }

  static async getAppointments(patientId) {
    const token = await xdsToken.getBearerToken();
    const applicationToken = await xdsToken.getApplicationToken();
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        timeout: 2000,
    };
    const url = `${ADMFHIR}/Appointment?patient.identifier=urn:oid:2.16.840.1.113883.13.237%7C${patientId}&_sort=-date`;
    const resp = await fetchWithTimeout(url, requestOptions)
                      .then(response => response.json())
                      .catch(err => AdminFhirRequests.catchError(err, 'getAppointments'));
    return resp;
  };

  static async getTasks(patientId) {
    const token = await xdsToken.getBearerToken();
    const applicationToken = await xdsToken.getApplicationToken();
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${applicationToken}`);
    myHeaders.append('X-User-Token', token);
    myHeaders.append('Content-Type', 'application/fhir+json');
    myHeaders.append('Accept-Encoding', 'gzip,deflate');
    myHeaders.append('Accept', 'application/fhir+json');

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        timeout: 2000,
    };
    const url = `${ADMFHIR}/Task?patient.identifier=urn:oid:2.16.840.1.113883.13.237%7C${patientId}&_sort=-authored-on&_count=100`;
    const resp = await fetchWithTimeout(url, requestOptions)
                      .then(response => response.json())
                      .catch(err => AdminFhirRequests.catchError(err, 'getAppointments'));
    return resp;
  };
}